/* General Styling */
body {
  font-family: 'Roboto', sans-serif; /* Professional font */
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
}

/* Dark and Light Mode Styles */
.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.light-mode {
  background-color: #ffffff;
  color: #121212;
}

/* Header layout with title on the left and mode toggle on the right */
.header {
  display: flex;
  justify-content: space-between; /* Distribute items to left and right */
  align-items: center;
  padding: 20px;
  width: 100%;
}

/* Title styling */
.header h1 {
  margin-left: 0;
}

/* Align the mode toggle button to the right */
.header .mode-toggle {
  margin-left: auto; /* Push the toggle button to the right */
  background: transparent;
  border: none;
  font-size: 14px;
  color: inherit;
  cursor: pointer;
  padding: 5px 10px;
  transition: opacity 0.3s ease;
  opacity: 0.7; /* Slightly transparent */
}

/* Show full opacity on hover */
.mode-toggle:hover {
  opacity: 1;
}

/* Remove outline when the button is focused */
.mode-toggle:focus {
  outline: none;
}

/* Emoji for light and dark modes on small screens */
.mode-toggle.light::before {
  content: "🌞";  /* Sun emoji for light mode */
  font-size: 24px;  /* Adjust the emoji size */
}

.mode-toggle.dark::before {
  content: "🌙";  /* Moon emoji for dark mode */
  font-size: 24px;  /* Adjust the emoji size */
}

/* Responsive adjustments for small screen sizes */
@media (max-width: 600px) {
  .mode-toggle {
    font-size: 20px;  /* Larger text for easier tap on mobile */
    padding: 5px;
  }

  .mode-toggle.light,
  .mode-toggle.dark {
    display: none; /* Hide text for both light and dark modes */
  }
}

/* Set all text in the main section to black */
main {
  color: black;  /* Set text color to black for all elements in the main section */
}

/* Container and other styles */
h1 {
  font-size: 2rem;
  font-weight: 600;
}

.container {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.instructions {
  font-size: 1.1rem;
  padding: 10px 0;
}

.file-upload {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.file-upload input {
  display: none;
}

.file-upload span {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.result {
  margin-top: 20px;
  font-size: 1rem;
}

.highlight {
  margin-top: 30px;
}

.paragraph {
  font-size: 0.9rem;
  margin-bottom: 20px;
  padding: 15px;
}









.list {
  font-size: 0.9rem;
  padding-left: 20px;
}

.list-item-red {
  color: #d9534f;
  font-weight: bold;
  margin-bottom: 10px;
}

.list-item-darkgray {
  color: rgb(124, 124, 124);
  margin-bottom: 10px;
}

.list-item {
  margin-bottom: 10px;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Responsive grid for instructions and content */
@media (min-width: 600px) {
  .highlight {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .paragraph, .list {
    grid-column: span 2;
  }
}

footer {
  margin-top: 30px;
  text-align: center;
  font-size: 0.8rem;
}

footer a {
  color: #007bff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer p {
  margin: 5px 0;
}




/* General paragraph styling */
.paragraph {
  font-size: 0.9rem;
  margin-bottom: 20px;
  padding: 15px;
  color: inherit; /* Ensure it inherits the text color from the parent */
}

/* Text color adjustments based on the mode */
.dark-mode .paragraph {
  color: #fff; /* White text in dark mode */
}

.light-mode .paragraph {
  color: #121212; /* Dark text in light mode for better contrast */
}

/* Dark mode container styling */
.dark-mode .container {
  background-color: gray; /* Light gray for dark mode */
  color: black; /* Blue text */
}

/* Light mode container styling */
.light-mode .container {
  background-color: aliceblue; /* Light yellow for light mode */
  color: inherit; /* Inherit text color from light mode settings */
}

/* Dark mode container-specific styling */
.dark-mode .container .list-item-red {
  color: yellow; /* Cyan for list-item-red */
}

.dark-mode .container .list-item-darkgray {
  color: khaki; /* Yellow for list-item-darkgray */
}

/* Dark mode link styling inside list-item-darkgray */
.dark-mode .list-item-darkgray .link {
  color: darkred; /* Magenta for links in dark mode */
}


video {
  width: 90%;
  max-width: 100%;
  height: auto;
  margin-left: 20px;
}

.video-container {
  margin-top: 2px; 
}

.center-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: .2vh; /* Full viewport height to center vertically */
  padding: 20px;
  margin-top: 20px;
}

.center-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff; /* Primary blue color */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.center-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slight zoom-in effect */
}

.center-button:active {
  background-color: #004080; /* Even darker blue on click */
  transform: scale(1); /* Reset zoom on click */
}


.video-wrapper {
  width: 100vw; /* Full viewport width */
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.preview-container {
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
}

.preview-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.player-container {
  position: relative;
  width: 100vw;
  max-width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}